import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        loadChildren: async () => (await import('@pages/landing-page')).routes,
    },
    {
        path: 'mobile/account-deletion',
        loadChildren: async () => (await import('@pages/account-deletion')).routes,
    },
    {
        path: 'mobile/privacy-policy',
        loadChildren: async () => (await import('@pages/privacy-policy')).routes,
    },
    {
        path: 'deeplink/:id',
        loadChildren: async () => (await import('@pages/redirect-page')).routes,
    },
    {
        path: '**',
        loadComponent: async () => (await import('@pages/landing-page/landing-page.component')).LandingPageComponent,
    },
];
